

























































































































































































































































import Vue from 'vue';
import Loader from "@/components/util/Loader.vue";
import UiString from "@/components/uistring/UiString.vue";
import SpriteIcon from "@/components/util/SpriteIcon.vue";
import CopyLink from "@/components/util/CopyLink.vue";
import SkillEffect from "@/components/skill/SkillEffect.vue";
import BigErrorBox from "@/components/util/BigErrorBox.vue";
import SkillStubLink from "@/components/skill/SkillStubLink.vue";
import SkillUsable from "@/components/skill/SkillUsable.vue";
import SkillExecution from "@/components/skill/SkillExecution.vue";
import SkillBasicInfo from "@/components/skill/SkillBasicInfo.vue";
import StateEffect from './StateEffect.vue';

import LoadingErrorable from "@/models/util/LoadingErrorable";
import ISkill from '@/models/skills/ISkill';
import ISkillLevel from '@/models/skills/ISkillLevel';
import { SkillType } from '@/models/skills/SkillEnums';
import ITypedMap from '@/models/util/ITypedMap';

import SkillProvider from '@/api/SkillProvider';
import ISkillEffectValue from '@/models/skills/ISkillEffectValue';
import { axiosErrorToString } from '@/helpers/AxiosErrorUtils';
import { WeaponType } from '@/models/items/ItemEnums';
import SkillAlternative from './SkillAlternative.vue';

interface IData {
    skillId: number;
    skillDataLoader: LoadingErrorable<ISkill, any>;
    skillLevelPvEDataLoader: LoadingErrorable<ISkillLevel[], any>;
    skillLevelPvPDataLoader: LoadingErrorable<ISkillLevel[], any>;
    pvp: boolean;
    activeSkillRank: number;
    forceShowAllEffects: boolean;
    activeRankData: ISkillLevel|null;
    
    pendingSkillRank: number;
}

export default Vue.extend({
    components: {
        BigErrorBox,
        CopyLink,
        Loader,
        SpriteIcon,
        UiString,
        SkillBasicInfo,
        SkillEffect,
        SkillStubLink,
        SkillUsable,
        SkillExecution,
        StateEffect,
        SkillAlternative,
    },
    props: {
        "skillSlug": {
            type: String as () => string,
        },
    },
    data(): IData {
        return {
            skillId: 0,
            skillDataLoader: new LoadingErrorable(),
            skillLevelPvEDataLoader: new LoadingErrorable(),
            skillLevelPvPDataLoader: new LoadingErrorable(),
            pvp: false,
            activeSkillRank: 0,
            forceShowAllEffects: false,
            activeRankData: null,
            pendingSkillRank: 0,
        };
    },
    watch: {
        skillSlug() {
            this.extractDataFromUrl();
        },
        skillId() {
            this.fetchData();
        },
        activeSkillRank() {
            this.setCurrentSkillLevelData();
            this.updateQueryParams();
            this.pendingSkillRank = this.activeSkillRank;
        },
        pendingSkillRank() {
            const n = Number(this.pendingSkillRank);
            if (!isNaN(n) && n > 0 && n <= this.realMaxSkillLevel) {
                this.activeSkillRank = n;
            }
        },
        pvp() {
            this.setCurrentSkillLevelData();
            this.$nextTick().then(() => this.updateQueryParams());
        },
    },
    computed: {
        skillData(): ISkill|null {
            return this.skillDataLoader.value || null;
        },
        skillLevelData(): ISkillLevel[] {
            return (this.pvp ? this.skillLevelPvPDataLoader.value : this.skillLevelPvEDataLoader.value) || [];
        },
        revSkillLevelData(): ISkillLevel[] {
            return (!this.pvp ? this.skillLevelPvPDataLoader.value : this.skillLevelPvEDataLoader.value) || [];
        },
        hasSkillIcon(): boolean {
            if (!this.skillData) {
                return false;
            }
            
            if (this.skillData.skillIcon.index == 0) {
                if (this.skillData.buffIcon && this.skillData.buffIcon.index >= 0) {
                    return false;
                }
                
                if (this.skillData.debuffIcon && this.skillData.debuffIcon.index >= 0) {
                    return false;
                }
            }

            return true;
        },
        realMaxSkillLevel(): number {
            if (this.skillLevelData) {
                let r = Math.max(...this.skillLevelData.map((l) => l.level));
                if (!isFinite(r)) {
                    return NaN;
                }

                return r;
            }

            return NaN;
        },
        realMinSkillLevel(): number {
            if (this.skillLevelData) {
                return Math.min(...this.skillLevelData.map((l) => l.level));
            }

            return 1;
        },
        maxNonTechLevel(): number {
            return this.skillData && this.skillData.spLevels || 0;
        },
        upTooltipText(): string {
            if (this.activeSkillRank >= this.maxNonTechLevel) {
                if (this.skillData) {
                    const techLvls = this.skillData.techLevels;
                    const overLvls = this.realMaxSkillLevel - this.skillData.spLevels;
                    if (techLvls > 0 && overLvls > 0) {
                        return `Hold SHIFT while clicking to jump to ${this.realMaxSkillLevel} (+${overLvls})`;
                    }
                }
            }
            
            return `Hold SHIFT while clicking to jump to ${this.maxNonTechLevel}`;
        },
        rankText(): string {
            if (this.skillData) {
                const techLvls = this.skillData.techLevels;
                const overLvls = this.activeSkillRank - this.skillData.spLevels;
                if (techLvls > 0 && overLvls > 0) {
                    return `${this.activeSkillRank} (+${overLvls})`;
                }
            }

            return String(this.activeSkillRank);
        },
        slug(): string {
            if (this.skillData) {
                return SkillProvider.skillSlug(this.skillData);
            } else {
                return "";
            }
        },
        link(): string {
            let queries: any = {};
            queries = Object.assign(queries, this.$route.query);
            delete queries["embed"];
            queries.region = this.$store.state.regionCode;

            let url = window.location.origin + this.$route.path;

            let qString = Object.keys(queries).map(k => `${k}=${queries[k]}`).join("&");
            if (qString.length > 0) {
                url += "?" + qString;
            }

            return url;
        },
        SkillType(): any {
            return SkillType;
        },
        weapons(): string {
            if (this.skillData) {
                return this.skillData.requiredWeapons.map((v) => {
                    const n = WeaponType[v];
                    if (n) {
                        return n;
                    }

                    return `Weap ${v}`;
                }).join(", ");
            }

            return "";
        },
    },
    mounted() {
        this.extractDataFromUrl();
    },
    methods: {
        async fetchData() {
            if (this.skillId <= 0) {
                return;
            }

            this.skillDataLoader.startLoad();
            this.skillLevelPvEDataLoader.startLoad();
            this.skillLevelPvPDataLoader.startLoad();

            try {
                const skillDataRes = await SkillProvider.getSkill(this.skillId);
                this.skillDataLoader.done(skillDataRes);

                this.updateSlug();

                let shouldLoadPvpFirst = this.pvp;

                // console.log(`Loading ${shouldLoadPvpFirst ? "pvp" : "pve"} data`);
                const sl1 = await SkillProvider.getSkillLevels(this.skillId, shouldLoadPvpFirst);
                if (shouldLoadPvpFirst) {
                    this.skillLevelPvPDataLoader.done(sl1);
                } else {
                    this.skillLevelPvEDataLoader.done(sl1);
                }

                if (this.skillLevelData.length > 0) {
                    if (this.activeSkillRank == 0) {
                        this.activeSkillRank = this.skillLevelData[0].level;
                    }
                }

                // console.log(`Loading ${!shouldLoadPvpFirst ? "pvp" : "pve"} data`);
                const sl2 = await SkillProvider.getSkillLevels(this.skillId, !shouldLoadPvpFirst);
                if (!shouldLoadPvpFirst) {
                    this.skillLevelPvPDataLoader.done(sl2);
                } else {
                    this.skillLevelPvEDataLoader.done(sl2);
                }

                if (this.revSkillLevelData.length > 0) {
                    if (this.activeSkillRank == 0) {
                        this.activeSkillRank = this.revSkillLevelData[0].level;
                    }
                }

                const maxLvl = this.realMaxSkillLevel;
                if (this.activeSkillRank > maxLvl) {
                    this.activeSkillRank = maxLvl;
                }

                this.setCurrentSkillLevelData();
                this.updateQueryParams();
            } catch (e) {
                this.skillDataLoader.failed(axiosErrorToString(e));
            }
        },

        extractDataFromUrl() {
            if (this.skillSlug) {
                const split = this.skillSlug.split("-", 2);
                const id = Number(split[0]);
                if (!isNaN(id)) {
                    this.skillId = id;
                }
            }

            const query = this.$route.query;
            if (query.pvp) {
                this.pvp = query.pvp === "1";
            }

            if (query.r) {
                const r = Number(query.r);
                if (!isNaN(r)) {
                    this.activeSkillRank = r;
                }
            }
        },

        effectValuesFor(index: number, rank: number): ISkillEffectValue|null {
            const rankData = this.skillLevelData.find((l) => l.level == rank) || null;
            if (!rankData) {
                return null;
            }

            return rankData.effectValues.find((e) => e.index == index) || null;
        },

        updateSlug() {
            if (this.slug != this.skillSlug) {
                this.$router.replace({
                    path: this.$route.path.replace(this.skillSlug, this.slug),
                    query: this.$route.query,
                });
            }
        },

        updateQueryParams() {
            if (this.skillLevelData == null) {
                return;
            }

            let query = Object.assign({}, this.$route.query);

            if (this.activeSkillRank <= this.realMinSkillLevel) {
                delete query.r;
            } else {
                query.r = String(this.activeSkillRank);
            }

            if (!this.pvp) {
                delete query.pvp;
            } else {
                query.pvp = "1";
            }

            this.$router.replace({
                path: this.$route.path,
                query: query,
            });
        },
        
        setCurrentSkillLevelData() {
            const skillLevelData = (this.pvp ? this.skillLevelPvPDataLoader.value : this.skillLevelPvEDataLoader.value) || [];
            // console.log(this.pvp, skillLevelData);
            this.activeRankData = skillLevelData.find((l) => l.level == this.activeSkillRank) || null;
        },

        down(evt: MouseEvent) {
            if (evt.shiftKey) {
                this.activeSkillRank = this.realMinSkillLevel;
            } else if (this.activeSkillRank > this.realMinSkillLevel) {
                --this.activeSkillRank;
            }
        },

        up(evt: MouseEvent) {
            if (evt.shiftKey) {
                if (this.activeSkillRank >= this.maxNonTechLevel) {
                    this.activeSkillRank = this.realMaxSkillLevel;
                } else {
                    this.activeSkillRank = this.maxNonTechLevel;
                }
            } else if (this.activeSkillRank < this.realMaxSkillLevel) {
                ++this.activeSkillRank;
            }
        }
        
    }

});
