import { render, staticRenderFns } from "./SkillView.vue?vue&type=template&id=64f1ba2f&scoped=true&"
import script from "./SkillView.vue?vue&type=script&lang=ts&"
export * from "./SkillView.vue?vue&type=script&lang=ts&"
import style0 from "./SkillView.vue?vue&type=style&index=0&id=64f1ba2f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f1ba2f",
  null
  
)

export default component.exports